<template>
  <div class="gkdl">
    <div class="content">
      <div class="left">
        <p class="left-a" @dblclick="show=!show">VisionProfessor</p>
        <img :src="src" alt="">
        <p class='left-sm'>扫码注册个人信息</p>
        <div @click="go()">顾客预约记录</div>
        <div @click="smdl">手机登录</div>
      </div>
      <div class="right">
        <div class="mzsr">
          <input class="sr" type="text" v-model="names" placeholder="姓名" @focus="namegetjd=true" @blur="sqjd('namegetjd')">
          <div class="mzsr-box" v-if="namegetjd">
            <div v-for="(item, idx) in CsList" @click="getCsItem(item.CustomerName, item.CustomerTel)">
              <p>名字：{{item.CustomerName}}</p>
              <p>电话号码：{{item.CustomerTel}}</p>
            </div>
            <p v-if="CsList.length < 1">暂无此顾客，请注册</p>
          </div>
        </div>
        <div class="mzsr">
          <input class="sr" type="text" v-model="iphone" placeholder="手机号码" @focus="telgetjd=true" @blur="sqjd('telgetjd')">
          <div class="mzsr-box" v-if="telgetjd">
            <div v-for="(item, idx) in CsList" @click="getCsItem(item.CustomerName, item.CustomerTel)">
              <p>名字：{{item.CustomerName}}</p>
              <p>电话号码：{{item.CustomerTel}}</p>
            </div>
            <p v-if="CsList.length < 1">暂无此顾客，请注册</p>
          </div>
        </div>
        <div class="box" v-if="!show" @click="ling">顾客登录</div>
        <div class="box" v-if="!show" @click="$router.push({path: '/myCustomers'})">我的顾客</div>
        <p v-if="show" style="margin: 20px auto;">
          <el-radio v-model="sex" label="1">男</el-radio>
          <el-radio v-model="sex" label="0">女</el-radio>
        </p>
        <el-date-picker
            v-if="show"
            v-model="rq"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期">
        </el-date-picker>

        <div class="upcs" style="padding-top: 20px;" v-if="show">
          <p>地区</p>
          <el-cascader
              :props="{value: 'AreaId', label: 'AreaName', children: 'ListArea'}"
              v-model="ssx"
              :options="options"
              @change="handleChange">

          </el-cascader>
        </div>
        <div class="upcs" style="padding-top: 20px;" v-if="show">
          <p>详细地址</p>
          <el-input class="upcsrs" v-model="CustomerAddress" placeholder="请输入内容"></el-input>
        </div>

        <div v-if="show" class="box" @click="zc">注册并登录</div>

        <div class="box" @click="$router.push({path: '/userLing'})">返<span style="padding-right: 2em;"></span>回</div>
      </div>

      <div class="sm" @click="getsm">
        <img src="@/assets/img/zcsm.png" alt="">
      </div>


    </div>

  </div>
</template>

<script>
import {Loading} from "element-ui";
import pf from "@/publicFn/baseFn"

export default {
  name: "customersLog",
  data() {
    return {
      erwurl: '',
      ssx: [],
      options: [],
      CustomerAddress: '',
      CustomerAreaId: '',
      CustomerAreaIndex: '',
      names: '',
      iphone: '',
      src: '',
      sex: '',
      rq: '',
      show: false,
      time: '',
      tleTime: '',
      CsList: [],
      namegetjd: false,
      telgetjd: false,
      dialogVisible: false
    }
  },
  watch: {
    names: function (n, o) {
      if (n !== '') {
        clearTimeout(this.time)
        this.time = setTimeout(() => {
          this.getCSlist('CustomerNameFull', n)
        }, 1000)
      }
    },
    iphone: function (n, o) {
      if (n !== '') {
        clearTimeout(this.tleTime)
        this.tleTime = setTimeout(() => {
          this.getCSlist('CustomerTelFull', n)
        }, 1000)
      }
    }
  },
  created() {
    this.$store.commit('Time/upTimeObj', {
      keys: 'customerLogin_normal',
      val: {
        ENTER: (new Date()).valueOf(),
        EXIT: 0
      }
    })


    let _this = this

    window.getWXCode = (code) => {
      if (code) {
        this.usergetcode(code)
      }
    }

    window.getCode = function (code) {
      if (code) {
        _this.usergetcode(code)
      }
    }

    this.getsf()


    window.getScanResult = function (res) {
      if (res) {
        _this._api.userCurdApi.codeLing(res)
        .then(res => {
          if (res.LoginOnCustomerNoResult.Customer) {
            let datas = res.LoginOnCustomerNoResult.Customer
            _this.$store.commit('users/upLogged', true)   //更新登陆状态
            _this.$store.commit('users/upCsUserItem', '')
            _this.$store.commit('users/upCsAge', pf.getYear(datas.CustomerBirthday))
            _this.$store.commit('users/UpCsUser', JSON.parse(JSON.stringify(datas)))
            _this.$store.commit('physicianVisits/upCancellation', false)
            _this.$emit('isLing', false)
          }
        })
      }
    }
    let url = this.$store.state.bases.shop.ShopWXQRCodeURL
    if (!url) url = pf.getUrl('customCompanyQRCode') + this.$store.state.users.user.Company.CompanyWXQRCode
    this.src =  url
    // console.log(this.src)
    if (this.$store.state.users.CsUserItem) {
      this.names = this.$store.state.users.CsUserItem.name
      this.iphone = this.$store.state.users.CsUserItem.tel
      this.ling()
    }
  },
  methods: {
    getsf() {
      this._api.publicApi.getAddress()
          .then(res => {
            // console.log(res)
            if (res.GetListResult && res.GetListResult.length) {
              this.options = res.GetListResult
              if (this.CustomerAreaIndex) {
                let idx = this.CustomerAreaIndex.split(',')
                this.ssx = [this.options[idx[0]].AreaId, this.options[idx[0]].ListArea[idx[1]].AreaId, this.options[idx[0]].ListArea[idx[1]].ListArea[idx[2]].AreaId]
                // console.log(this.ssx)
              }
            }
          })
    },
    handleChange(e) {
      // console.log(e)
      let idx = [0, 0, 0]
      for (let i = 0; i < this.options.length; i++) {
        if (e[0] == this.options[i].AreaId) {
          idx[0] = i
          for (let j = 0; j < this.options[i].ListArea.length; j++) {
            if (this.options[i].ListArea[j].AreaId == e[1]) {
              idx[1] = j
              for (let k = 0; j < this.options[i].ListArea[j].ListArea.length; k++) {
                if (this.options[i].ListArea[j].ListArea[k].AreaId == e[2]) {
                  idx[2] = k
                  break
                }
              }
              break
            }
          }
          break
        }
      }

      this.CustomerAreaIndex = idx.join(',')

      this.CustomerAreaId = e[2]
    },
    back() {
      this.$router.back(-1)
    },
    go() {
      this.$router.push({path: '/reservationRecord'})
    },
    ling() {
      if (!this.iphone || !this.names) {
        this.$msgbox('请输入姓名或者电话号码！','提示', {
          confirmButtonText: '确定',
        })
        return
      }
      let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '加载中...'
      });
      this._api.userCurdApi.CsLanding(this.names, this.iphone)
      .then(res => {
        loadingInstance.close();
        if (res.GetResult) {
          let nian = pf.getYear(res.GetResult.CustomerBirthday)
          this.$store.commit('users/upLogged', true)   //更新登陆状态
          this.$store.commit('users/upCsUserItem', '')
          this.$store.commit('users/upCsAge', nian)
          this.$store.commit('users/UpCsUser', res.GetResult)
          this.$store.commit('physicianVisits/upCancellation', false)
          this.$emit('isLing', false)

          this._api.publicApi.addTime('customerLogin_normal', ((new Date()).valueOf() - this.$store.state.Time.timeObj.customerLogin_normal.ENTER)/1000)
          this.$store.commit('Time/initTimeObj', {
            keys: "customerLogin_normal",
            val: {
              ENTER: 0,
              EXIT: 0
            }
          })
        }else {
          this.$message.error('姓名或电话错误');
        }
      })
      .catch(err => {
        loadingInstance.close();
        // console.log(err)
      })
    },

    zc() {
      if (!this.iphone || !this.rq || !this.names) {
        return this.$alert('请输入必要信息!', '提示')
      }
      if (!this.sex) {
        return  this.$msgbox('请选择性别！','提示', {
          confirmButtonText: '确定',
        })
      }
      if (this.rq && pf.getYear(this.rq) < 1) {
        return  this.$msgbox('年龄不得小于1岁！','提示', {
          confirmButtonText: '确定',
        })
      }
      let lo = this.$loading({
        text: '注册中...',
        background: 'rgba(0, 0, 0, 0.8)'
      })
      this._api.userCurdApi.csZc(this.names, this.iphone, this.sex, this.rq, this.CustomerAreaIndex, this.CustomerAreaId, this.CustomerAddress)
      .then(res => {
        lo.close()
        if (res.Login190826Result) {
          let nian = pf.getYear(res.Login190826Result.CustomerBirthday)
          this.$store.commit('users/upLogged', true)   //更新登陆状态
          this.$store.commit('users/upCsUserItem', '')
          this.$store.commit('users/upCsAge', nian)
          this.$store.commit('users/UpCsUser', JSON.parse(JSON.stringify(res.Login190826Result)))
          this.$store.commit('physicianVisits/upCancellation', false)
          this.$emit('isLing', false)

          this._api.publicApi.addTime('customerLogin_normal', ((new Date()).valueOf() - this.$store.state.Time.timeObj.customerLogin_normal.ENTER)/1000)
          this.$store.commit('Time/initTimeObj', {
            keys: "customerLogin_normal",
            val: {
              ENTER: 0,
              EXIT: 0
            }
          })
          this.$store
        }else {
          this.$alert('注册失败！', '提示')
        }
      })
    },

    getsm() {
      if (window.bound) {
        window.bound.scanQRCode()
      }

      if (window.androids) {
        const  _this = this
        window.rdata = function (e) {
          if (e) {
            if (e != 99) {
              let loadingInstance = Loading.service({
                background: 'rgba(0, 0, 0, 0.7)',
                text: '登录中...'
              });
              _this._api.userCurdApi.codeLing(e)
                  .then(res => {
                    loadingInstance.close()
                    if (res.LoginOnCustomerNoResult) {
                      let obj = res.LoginOnCustomerNoResult.Customer
                      let nian = pf.getYear(obj.CustomerBirthday)
                      _this.$store.commit('users/upLogged', true)   //更新登陆状态
                      _this.$store.commit('users/upCsUserItem', '')
                      _this.$store.commit('users/upCsAge', nian)
                      _this.$store.commit('users/UpCsUser', obj)
                      _this.$store.commit('physicianVisits/upCancellation', false)
                      _this.$emit('isLing', false)
                    }
                  })
            }else {
              _this.$alert('扫码失败', '提示')
            }
          }
        }
        window.androids.sm("")
      }


      /*if (window.androids) {
        const  _this = this
        window.rdata = function (e) {
          if (e) {
            if (e != 99) {
              let loadingInstance = Loading.service({
                background: 'rgba(0, 0, 0, 0.7)',
                text: '登录中...'
              });
              _this._api.userCurdApi.codeLing(e)
                  .then(res => {
                    loadingInstance.close()
                    if (res.LoginOnCustomerNoResult) {
                      let obj = res.LoginOnCustomerNoResult.Customer
                      let nian = pf.getYear(obj.CustomerBirthday)
                      _this.$store.commit('users/upLogged', true)   //更新登陆状态
                      _this.$store.commit('users/upCsUserItem', '')
                      _this.$store.commit('users/upCsAge', nian)
                      _this.$store.commit('users/UpCsUser', obj)
                      _this.$store.commit('physicianVisits/upCancellation', false)
                      _this.$emit('isLing', false)
                    }
                  })
            }else {
              _this.$alert('取消扫码', '提示')
            }
          }
        }
        window.androids.sm()
      }*/
    },

    getCSlist(types, val) {
      this._api.userCurdApi.getCsList(types, val)
      .then(res => {
        if (res.GetListResult && res.GetListResult.length) {
          this.CsList = [...res.GetListResult]
        }else {
          this.CsList = []
        }
      })
    },
    getCsItem(names, tel) {
      this.names = names
      this.iphone = tel
    },
    sqjd(keys) {
      setTimeout(() => {
        this[keys] = false
      }, 300)
    },

    smdl() {
      if (window.androids) {
        window.androids.openCode()
        return
      }
      if (window.bound) {
        window.bound.wxCode();
      }

    },

    usergetcode(code) {
      let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '登录中...'
      });
      this._http.post('https://interface2.visumall.com/OtherSystem/WXOAuth_Token.ashx?cmd=Get_UserInfo', {
        "token":{
          "sToken":"8BED23DE028E0E9966B8CE9E8F77DB89",
          "TimeSpan":"1535942069232"
        },
        "code": code,
        "companyid": this.$store.state.users.user.Company.CompanyId
      })
      .then(res => {
        loadingInstance.close()
        if (res.customer) {
          let obj = res.customer
          let nian = pf.getYear(obj.CustomerBirthday)
          this.$store.commit('users/upLogged', true)   //更新登陆状态
          this.$store.commit('users/upCsUserItem', '')
          this.$store.commit('users/upCsAge', nian)
          this.$store.commit('users/UpCsUser', obj)
          this.$store.commit('physicianVisits/upCancellation', false)
          this.$emit('isLing', false)
        }else {
          this.$alert('用户未注册，请扫左侧二维码注册', '提示')
        }
      })
    }
  },
  beforeDestroy() {
    // this.$store.commit('Time/upTimeExit', {
    //   keys: "customerLogin_normal",
    //   outTime:  (new Date()).valueOf()
    // })
  }
}
</script>

<style scoped lang="scss">
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 88vw;
    height:  76vh;
  }
  .left {
    img {
      display: block;
      width: 3rem;
      height: auto;
    }
    .left-a {font-size: .44rem;}
    .left-sm {font-size: .18rem; color: #8a8888;}
    div {
      width: 2.7rem;
      padding: 10px 0;
      color: #8a8888;
      font-size: .18rem;
      border: 1px solid #8a8888;
      margin: 0 auto;
      margin-top: .2rem;
      cursor: pointer;
    }
  }
  .right {
    padding:0 2rem;
    color: #8a8888;
    font-size: .18rem;
    .sr {
      display: block;
      border: none;
      text-align: center;
      border-bottom: 1px solid #000000;
      font-size: 14px;
      width: 2.3rem;
      padding: 10px 0;
      outline: none;
      margin: 20px auto;
    }
    .box {
      border:1px solid #8a8888;
      cursor: pointer;
      margin-top: 40px;
      padding: 10px 0;
    }
  }
  .sm {
     cursor: pointer;
    img {
      width: 2rem;
    }
  }
  .mzsr {
    position: relative;
    .mzsr-box {
      position: absolute;
      width: 200px;
      height: 200px;
      overflow-y: auto;
      right: -210px;
      top: 0;
      background: #e3e3e3;
      font-size: 14px;
      padding: 6px 0;
      color: black;
      border-radius: 10px;
      div {
        padding: 0 6px;
        text-align: left;
        cursor: pointer;
        border-bottom: 2px solid #FFFFFF;
        p {width: 100%;}
        &:hover {
          background: #c6c6c6;
        }
      }
    }
    .mzsr-box::after {
      content: '';
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-right: 10px solid #e3e3e3;
      position: absolute;
      left: -20px;
      top: 10px;
    }
  }
</style>
